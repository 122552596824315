<template>
  <div>
    <b-row>
        <b-col>
        <b-card class="my-card m-0">
            <b-table
                striped
                small
                id="myTabless"
                bordered
                selectable
                selected-variant="primary"
                select-mode="single"
                responsive="sm"
                v-hotkey="{ 'insert' :  getCreat, 'enter' : editForm, 'alt+f5' : copyTariffPerformer,}"
                :items="tariffPerformerSetting"
                :fields="fields"
                head-row-variant="secondary"
                @row-contextmenu="handleContextMenuEvent"
                @row-clicked="clicked"
                @row-dblclicked="editForm"
                >
                <template #cell(checkbox)="row">
                        <b-form-checkbox
                        v-model="row.detailsShowing"
                        plain
                        class="vs-checkbox-con"
                        @change="row.toggleDetails"
                        >
                        </b-form-checkbox>
                    </template> 
                <template #cell(blocked)="data">
                    <span>
                        {{data.item.blocked === 1 ? 'Да' : 'Не'}}
                    </span>
                </template>
                <template #cell(weekdays)="data">
                    <span v-for="(weekdays) in data.item.weekdays" :key="weekdays">
                        ({{ weekdays }})
                    </span>
                </template>
                <template #cell(price)>
                    <span class="reference-field" @click.prevent="getPrice">
                        (***)
                    </span>
                </template>
                <template #cell(prices)="data">
                    <span v-for="prices in data.item.prices" :key="prices.id">
                       {{ prices.id }}
                    </span>
                </template>
            </b-table>
            <vue-context ref="menu">
                <li>
                    <b-link
                    class="d-flex align-items-center"
                    @click.prevent="getCreat"
                    >
                    <feather-icon
                        icon="PlusIcon"
                    />
                    <span class="ml-75">Добавить</span>
                    <span style="margin-left: 45px;">Ins</span>
                    </b-link>
                    
                    <b-link
                    class="d-flex align-items-center"
                    @click.prevent="editForm"
                    >
                    <feather-icon
                        icon="Edit3Icon"
                    />
                    <span class="ml-75">Изменить</span>
                    <span style="margin-left: 30px;">Enter</span>
                    </b-link>
                    <b-link
                    class="d-flex align-items-center"
                    @click.prevent="copyTariffPerformer"
                    >
                    <feather-icon
                        icon="CopyIcon"
                    />
                    <span class="ml-75">Копировать</span>
                    <span style="margin-left: 10px;">Alt+F5</span>
                    </b-link>
                    <b-link
                    class="d-flex align-items-center"
                    @click.prevent="deleteTariffSetting"
                    >
                    <feather-icon
                        icon="EditIcon"
                    />
                    <span class="ml-75">Удалить</span>
                    </b-link>
                    <hr>
                    <b-link
                    class="d-flex align-items-center"
                    @click="autoUpdate"
                    >
                    <img :src="iconChect">
                    <span class="ml-75">Автообновление</span>
                    </b-link>
                </li>
            </vue-context>
            </b-card> 
        </b-col>
    </b-row>
  </div>
</template>

<script>
import VueContext from 'vue-context'
import { resizeable } from "@/utils/TableFunctions/resizeable.js"
import { cellSelect } from "@/utils/TableFunctions/cellSelect";

export default {
    components: {
        VueContext,
    },
    props: ['tariffPerformerSetting'],
    data() {
        return {
            fields: [
                    {key: 'checkbox', label: '', thStyle: {width: '5px'}},
                    {key: 'base_tariff_performer_name', label: 'Тип', sortable: true, thStyle: {width: '50px'}},
                    {key: 'priority', label: 'Приоритет', sortable: true, thStyle: {width: '50px'}},
                    {key: 'weekdays', label: 'Дни недели', sortable: true, thStyle: {width: '50px'}},
                    {key: 'order_type_name', label: 'Тип тарифа заказа', sortable: true, thStyle: {width: '50px'}},
                    {key: 'start_time', label: 'Время начала', sortable: true, thStyle: {width: '50px'}},
                    {key: 'end_time', label: 'Время окончания', sortable: true, thStyle: {width: '50px'}},
                    {key: 'price', label: 'Цена', sortable: true, thStyle: {width: '50px'}},
                    {key: 'write_commission_assigning_performer', label: 'Порог цени', sortable: true, thStyle: {width: '50px'}},
                    {key: 'price_limit', label: 'По цене', sortable: true, thStyle: {width: '50px'}},
                    {key: '7', label: 'По расстояние', sortable: true, thStyle: {width: '50px'}},
                    {key: '8', label: 'По времени', sortable: true, thStyle: {width: '50px'}},
                    {key: 'blocked', label: 'Статус', sortable: true, thStyle: {width: '50px'}},
                    {key: 'status_performer_name', label: 'Список', sortable: true, thStyle: {width: '50px'}},
                    {key: 'base_tariff_performer_id', label: 'ID тарифа', sortable: true, thStyle: {width: '50px'}},
                ],
                id: null,
                is_active: false,
                iconChect: require('@/assets/images/icons/square.svg'),
                iconsSquare: require('@/assets/images/icons/square.svg'),
                iconsCheck: require('@/assets/images/icons/checkSquareIcon.svg')
            }
        },
    mounted() {
        resizeable()
    },
    methods: {
        handleContextMenuEvent(item, index, event){
            event.preventDefault();
            this.$refs.menu.open(event)
            this.id = item.id
        },
        getPrice() {
            this.$emit('getPrice')
        },
        autoUpdate() {
            this.iconChect = this.is_active ? this.iconsSquare : this.iconsCheck;
            this.is_active = !this.is_active;
            this.$emit('timerAfto', this.is_active)
        },
        getCreat() {
            this.$bvModal.show(this.$route.name + 'CreateModal')
        },
        editForm() {
            let id = this.id
            this.$emit('editForm', id)
        },
        copyTariffPerformer() {
            let id = this.id
            this.$emit('copyTariffPerformer', id)
        },
        deleteTariffSetting() {
            let id = this.id
            this.$emit('deleteTariffSetting', id)
        },
        clicked(item, index, event){
            this.id = item.id
            this.$store.state.filterPlus.tableIndex = index

            const clickedElement = event.target;
            const clickedCell = clickedElement.closest('td');

            if (!clickedCell) {
                return;
            }

            const columnIndex = Array.from(clickedCell.parentNode.children).indexOf(clickedCell);

            if (columnIndex < 0 || columnIndex >= this.fields.length) {
                return;
            }

            const clickedField = this.fields[columnIndex];
            this.$store.state.filterPlus.keyFilter = clickedField.key;
            this.$store.state.filterPlus.filterPlus = (event.target.innerHTML).trim()
            cellSelect()
        },
    }
}
</script>

<style lang="scss" scoped>
@import '@/assets/scss/table.scss';
.my-card .card-body{
    padding: 0px;
}
.my-card{
overflow: auto; 
height: calc(100vh - 92px);
}
@media (max-height: 700px) {
  .my-card{
    overflow: auto; 
    height: 530px;
    }
}
</style>
